import React from "react";
import {
  Image,
  Stack,
  Heading,
  Text,
  Divider,
  CardFooter,
} from "@chakra-ui/react";

interface Item {
  title: string;
  description: string | Array<{ name: string; price: string; desc?: any }>;
  imageUrl: string;
  backgroundColor: string;
  textColor: string;
  price: string;
  preparationTime: string;
}

interface ItemCardBodyProps {
  item: Item;
  category: { cat_name: string };
}

const ItemCardBody: React.FC<ItemCardBodyProps> = ({ item, category }) => {
  let isAdicionalOrTroca =
    item.title.toLowerCase() === "atenção!" ||
    category.cat_name.toLowerCase() === "vinho" ||
    category.cat_name.toLowerCase() === "espumante";

  return (
    <React.Fragment>
      {!isAdicionalOrTroca && (
        <Image
          src={item.imageUrl}
          alt="imagem sendo carregado..."
          borderRadius="lg"
          loading="lazy"
        />
      )}
      <Stack mt="6" spacing="3">
        <Heading size="md" color="papaya_dark.1" whiteSpace="pre-line">
          {item.title}
        </Heading>
        {renderDescription(item.description, category)}

        <Text
          color="papaya_dark.1"
          fontSize="2xl"
          mx={"1.5rem"}
          mb={"1.5rem"}
          whiteSpace="pre-line"
        >
          {item.price}
        </Text>
      </Stack>
      {!isAdicionalOrTroca && (
        <>
          <Divider />
          <CardFooter>
            <Text>TEMPO : {item.preparationTime}</Text>
          </CardFooter>
        </>
      )}
    </React.Fragment>
  );
};

const renderDescription = (
  description:
    | string
    | Array<{
        name: string;
        price: string;
        desc?: any;
      }>,
  category: { cat_name: string }
) => {
  if (
    category.cat_name === "Croissant, Pão e Ovo" ||
    category.cat_name === "Croissant & Croffle Doce" ||
    category.cat_name === "Suco e Frutas" ||
    category.cat_name === "Vinho" ||
    category.cat_name === "Espumante" ||
    category.cat_name === "Sopa"
  ) {
    return Array.isArray(description) ? (
      <ul
        style={{
          marginBottom: "1rem",
          marginTop: "1rem",
          marginLeft: "2rem",
          textAlign: "left",
        }}
      >
        {description.map((itemDesc, index) => (
          <>
            <li
              key={index}
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "left",
                marginRight: "2rem",
              }}
            >
              <div>
                <p style={{ textTransform: "uppercase" }}>
                  {itemDesc.name}
                </p>
              </div>
              <div>
                <p>{itemDesc.price}</p>
              </div>
            </li>
            {itemDesc.desc && (
              <p style={{ marginBottom: "2rem", whiteSpace: "pre-line", marginRight: "2rem" }}>
                <small>{itemDesc.desc}</small>
              </p>
            )}
          </>
        ))}
      </ul>
    ) : (
      <Text whiteSpace="pre-line">{description}</Text>
    );
  } else {
    return (
      <Text whiteSpace="pre-line" mb={"1rem"} mx={"1rem"}>
        {Array.isArray(description)
          ? description.map((itemDesc, index) => (
              <React.Fragment key={index}>
                {itemDesc.name} {itemDesc.price}
              </React.Fragment>
            ))
          : description}
      </Text>
    );
  }
};

export default ItemCardBody;
