import React, { useState } from 'react';

const Spoiler: React.FC = () => {
  const [isHovered, setIsHovered] = useState(false);

  const spoilerStyle: React.CSSProperties = {
    color: isHovered ? 'white' : 'transparent',
    backgroundPosition: 'right',
    backgroundSize: '100% 100%',
    backgroundColor: '#88362B', 
    display: 'inline',
    backgroundRepeat: 'no-repeat',
    transition: 'all 400ms ease-in-out',
    marginTop: '-5%',
    transform: 'rotate(2.7deg)',
    textAlign: 'center',
  };

  const handleMouseOver = () => {
    setIsHovered(true);
  };

  const handleMouseOut = () => {
    setIsHovered(false);
  };

  return (
    <span
      className="spoiler"
      style={spoilerStyle}
      onMouseOver={handleMouseOver}
      onMouseOut={handleMouseOut}
    >
      além de croissant, fabricamos outras delícias artesanalmente!
    </span>
  );
};

export default Spoiler;