import { Flex } from "@chakra-ui/react";
import Footer from "./section/Footer";
import Headerimg from "./section/Header";
import History from "./section/History";

export default function IntroPage() {
  return (
    <Flex flexDirection={"column"} backgroundColor={"papaya_dark.1"} color={'brick_dark.5'}>
      <Headerimg />
      <History />
      <Footer />
    </Flex>
  );
}
