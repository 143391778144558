import {
  Box,
  Text,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
} from "@chakra-ui/react";

function NavbarMenu() {
  return (
    <Box
      textAlign={"center"}
      backgroundColor={"brick_dark.10"}
      color={"white"}
      h={"100%"}
    >
      <Menu>
        <MenuButton
          w={"100%"}
          h={"100%"}
          alignContent={"center"}
          fontSize={"larger"}
        >
          <Text>CLIQUE AQUI PARA NAVEGAR MAIS RÁPIDO!</Text>
        </MenuButton>
        <MenuList color={"brick_dark.10"}>
          <MenuItem as="a" href="#novidades">
            Novidades
          </MenuItem>
          <MenuItem as="a" href="#gyoza">
            Gyoza
          </MenuItem>
          <MenuItem as="a" href="#croissant, pão e ovo">
            Croissant, Pão e Ovo
          </MenuItem>
          <MenuItem as="a" href="#sobremesa">
            Sobremesa
          </MenuItem>
          <MenuItem as="a" href="#boulangerie">
            Boulangerie
          </MenuItem>
          <MenuItem as="a" href="#confeitaria">
            Confeitaria
          </MenuItem>
          <MenuItem as="a" href="#café quente">
            Café Quente
          </MenuItem>
          <MenuItem as="a" href="#chocolate quente">
            Chocolate Quente
          </MenuItem>
          <MenuItem as="a" href="#chá especial">
            Chá Especial
          </MenuItem>
          <MenuItem as="a" href="#café gelado">
            Café Gelado
          </MenuItem>
          <MenuItem as="a" href="#milkshake">
            Milkshake
          </MenuItem>
          <MenuItem as="a" href="#suco e frutas">
            Suco e Frutas
          </MenuItem>
          <MenuItem as="a" href="#drinks e coquetel">
            Drinks e Coquetel
          </MenuItem>
          <MenuItem as="a" href="#vinho">
            Vinho
          </MenuItem>
          <MenuItem as="a" href="#espumante">
            Espumante
          </MenuItem>
        </MenuList>
      </Menu>
    </Box>
  );
}

export default NavbarMenu;
