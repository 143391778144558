import { ChakraProvider } from "@chakra-ui/react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Navbar from "./component/Navbar";
import CardapioPage from "./page/cardapio/CardapioPage";
import IntroPage from "./page/intro/IntroPage";
import ErrorPage from "./page/error/ErrorPage";
import DeliveryPage from "./page/delivery/DeliveryPage";
import EncomendaPage from "./page/encomenda/EncomendaPage";
import ReservaPage from "./page/reserva/ReservaPage";
import ContatoPage from "./page/contato/ContatoPage";
import theme from './data/theme';

export const App = () => (
  <ChakraProvider theme={theme} cssVarsRoot={undefined}>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Navbar />}>
            <Route index element={<IntroPage />} />
            <Route path="intro" element={<IntroPage />} />
            <Route path="cardapio" element={<CardapioPage />} />
            <Route path="delivery" element={<DeliveryPage />} />
            <Route path="encomenda" element={<EncomendaPage />} />
            <Route path="reserva" element={<ReservaPage />} />
            <Route path="contato" element={<ContatoPage />} />
            <Route path="*" element={<ErrorPage />} />
          </Route>
        </Routes>
      </BrowserRouter>
  </ChakraProvider>
);
