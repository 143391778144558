export const menuData = [
  {
    cat_name: "Gyoza",
    cat_text: "Refeições Internacionais",
    cat_img: "./product/1CATEGORY/gyoza.jpg",
    items: [
      {
        title: "Fritas",
        description:
          "uma porção GENEROSA de batata frita, 330g.\n(acompanhado por 1 molho de tomate)",
        imageUrl: "./product/gyoza/batata-frita.jpeg",
        backgroundColor: "brick_dark.5",
        textColor: "white",
        price: "R$ 16",
        preparationTime: "15 mins",
      },
      {
        title: "Primavera",
        description:
          "3und de rolinho primavera CROCANTE.\n(escolhe o sabor: verdura ou frango)\n(acompanhado por 1 molho de tomate)",
        imageUrl: "./product/gyoza/rolinho-primavera.jpeg",
        backgroundColor: "brick_dark.5",
        textColor: "white",
        price: "R$ 17",
        preparationTime: "15 mins",
      },
      {
        title: "Sopa Caseira",
        description:
          "sopa caseira de FRANGO 300g.\n\nDICA: adicione o pão sourdough por 5 reais.\n\nPÃO SOURDOUGH: pão egipcio fermentado por “fermento selvagem”, possuindo um gosto levemente ácido e agradável, que trás um equilíbrio na sua sopa!",
        imageUrl: "./product/gyoza/sopa.jpeg",
        backgroundColor: "brick_dark.5",
        textColor: "white",
        price: "15",
        preparationTime: "10 mins",
      },
      {
        title: "Chǎo Miàn 炒麵 \n( verduras )",
        description:
          "macarrão servido generosamente com verduras assados na chapa no estilo taiwanês",
        imageUrl: "./product/gyoza/chao-mian.jpeg",
        backgroundColor: "brick_dark.5",
        textColor: "white",
        price: "R$ 20",
        preparationTime: "20 mins",
      },
      {
        title: "Chǎo Miàn 炒麵 \n( camarão teryaki )",
        description:
          "macarrão servido generosamente com camarão e verduras assados na chapa ao tempero taiwanesa",
        imageUrl: "./product/gyoza/chao-mian-camarao.jpeg",
        backgroundColor: "brick_dark.5",
        textColor: "white",
        price: "R$ 32",
        preparationTime: "30 mins",
      },
      {
        title: "Jian Jiao ❤️",
        description:
          "10und de guioza, massa fina FRITO e recheado com frango e legumes ao tempero taiwanês.\n\n(acompanhado por 1 molho de shoyu)\n\nORIGEM: típico da culinária chinesa, que se difundiu para a culinária japonesa e do resto da Ásia.",
        imageUrl: "./product/gyoza/guioza.jpeg",
        backgroundColor: "brick_dark.5",
        textColor: "white",
        price: "R$ 26",
        preparationTime: "20 mins",
      },
      {
        title: "Niu Rou Mian",
        description:
          "macarrão servido DELICIOSAMENTE com um caldo taiwanês à base de carne e ainda coberto ao pedaços de carne bovina com verduras.\n\nOBS: consulte a disponibilidade com os atendentes.",
        imageUrl: "./product/gyoza/niu-rou-mian.jpeg",
        backgroundColor: "brick_dark.5",
        textColor: "white",
        price: "R$ 26",
        preparationTime: "20 mins",
      },
      {
        title: "Frango Frito",
        description:
          "600g de frango crocante fritado NA HORA.\n(acompanhado por 1 molho de alho artensanal)",
        imageUrl: "./product/gyoza/frango-frito.jpeg",
        backgroundColor: "brick_dark.5",
        textColor: "white",
        price: "R$ 37",
        preparationTime: "30 mins",
      },
      {
        title: "Camarão Refogado",
        description:
          "camarão refogado assado na CHAPA ao molho teryaki e verduras em tirinhas no estilo taiwanês.\n(acompanhado no centro por 1 porção de cream cheese)",
        imageUrl: "./product/gyoza/camarao-refogado.jpeg",
        backgroundColor: "brick_dark.5",
        textColor: "white",
        price: "R$ 45",
        preparationTime: "30 mins",
      },
    ],
  },
  {
    cat_name: "Croissant, Pão e Ovo",
    cat_text: "Sanduíches em geral",
    cat_img: "./product/1CATEGORY/croissant-pao-e-ovo.jpg",
    items: [
      {
        title: "Mini 9cm / Big 18cm \n( croissant salgado )",
        description: [
          { name: "MUSSARELA", price: "R$ 8 / 15", desc: "(queijo mussarela)" },
          { name: "REINO", price: "R$ 10 / 18", desc: "(queijo do reino)" },
          {
            name: "KING PERU",
            price: "R$ 13 / 23",
            desc: "(presunto peru e queijo do reino)",
          },
          {
            name: "PATEZINHO",
            price: "R$ 13 / 23",
            desc: "(patê de cream cheese, requeijão e presunto peru)",
          },
          {
            name: "BRITÂNICO",
            price: "R$ 15 / 25",
            desc: "(presunto peru, queijo mussarela, tomate e catupiry)",
          },
          {
            name: "ALHO (20mins)",
            price: "R$ 15 / 25",
            desc: "(patê de alho ao queijo parmesão e catupiry)",
          },
          {
            name: "FRANGO CATUPIRY ❤️",
            price: "R$ 16 / 26",
            desc: "(frango temperado ao tomate, cebola, queijo mussarela e catupiry)",
          },
          {
            name: "SERTANEJO",
            price: "R$ 17 / 27",
            desc: "(carne de sol, cebola, queijo mussarela e coalho)",
          },
          {
            name: "4 QUEIJOS",
            price: "R$ 17 / 27",
            desc: "(quatro tipos de queijos: mussarela, cheddar, coalho e catupiry)",
          },
          {
            name: "BACON",
            price: "R$ 18 / 28",
            desc: "(bacon na chapa, ovo frito, presunto peru, queijo mussarela e catupiry)",
          },
          {
            name: "PARMA BRIE",
            price: "R$ 19 / 29",
            desc: "(presunto parma ao queijo brie e mel)",
          },
          {
            name: "CAMARÃO (20mins)",
            price: "R$ -- / 32",
            desc: "(camarão na chapa, queijo do reino e cream cheese)",
          },
          {
            name: "SALMÃO",
            price: "R$ -- / 33",
            desc: "(salmão, saladas, cebolinha e cream cheese)",
          },
        ],
        imageUrl: "./product/croissant/croissant-reino.jpeg",
        backgroundColor: "brick_dark.5",
        textColor: "white",
        price: " OBS: os itens desta categoria serve-se quente.",
        preparationTime: "15-30 mins",
      },
      {
        title: "Mascavo 12cm \n( pão de hambúrguer )",

        description: [
          { name: "MUSSARELA", price: "R$ 10", desc: "(queijo mussarela)" },
          { name: "REINO", price: "R$ 12", desc: "(queijo do reino)" },
          {
            name: "KING PERU",
            price: "R$ 14",
            desc: "(presunto peru e queijo do reino)",
          },
          {
            name: "BRITÂNICO",
            price: "R$ 17",
            desc: "(presunto peru, queijo mussarela, tomate e catupiry)",
          },
          {
            name: "FRANGO CATUPIRY",
            price: "R$ 18",
            desc: "(frango temperado ao tomate, cebola, queijo mussarela e catupiry)",
          },
          {
            name: "SERTANEJO",
            price: "R$ 19",
            desc: "(carne de sol, cebola, queijo mussarela e coalho)",
          },
          {
            name: "BACON 👍",
            price: "R$ 20",
            desc: "(bacon na chapa, ovo frito, presunto peru, queijo mussarela e catupiry)",
          },
        ],
        imageUrl: "./product/pao/pao-mascavo.jpeg",
        backgroundColor: "brick_dark.5",
        textColor: "white",
        price: " OBS: os itens desta categoria serve-se quente.",
        preparationTime: "10-15 mins",
      },
      {
        title: "Omelete 15cm \n( ovo )",
        description: [
          { name: "MUSSARELA", price: "R$ 12", desc: "(queijo mussarela)" },
          { name: "REINO", price: "R$ 15", desc: "(queijo do reino)" },
          {
            name: "KING PERU",
            price: "R$ 17",
            desc: "(presunto peru e queijo do reino)",
          },
          {
            name: "BRITÂNICO 👍",
            price: "R$ 19",
            desc: "(presunto peru, queijo mussarela, tomate e catupiry)",
          },
          {
            name: "FRANGO CATUPIRY",
            price: "R$ 22",
            desc: "(frango temperado ao tomate, cebola, queijo mussarela e catupiry)",
          },
          {
            name: "SERTANEJO",
            price: "R$ 24",
            desc: "(carne de sol, cebola, queijo mussarela)",
          },
          {
            name: "BACON",
            price: "R$ 24",
            desc: "(bacon na chapa, ovo frito, presunto peru, queijo mussarela e catupiry)",
          },
        ],
        imageUrl: "./product/ovo/omelete.jpg",
        backgroundColor: "brick_dark.5",
        textColor: "white",
        price: " OBS: os itens desta categoria serve-se quente.",
        preparationTime: "15-20 mins",
      },
    ],
  },
  {
    cat_name: "Croissant & Croffle Doce",
    cat_text: "Folhadas Doce",
    cat_img: "./product/1CATEGORY/sobremesa.jpeg",
    items: [
      {
        title: "Mini 9cm / Big 18cm \n( croissant doce )",
        description: [
          {
            name: "ROJO",
            price: "R$ 8 / 15",
            desc: "(geleia de morango natural e artesanal da casa)",
          },
          {
            name: "OVOMALTINE",
            price: "R$ 10 / 22",
            desc: "(creme crocante de ovomaltine)",
          },
          {
            name: "KINDER",
            price: "R$ 13 / 23",
            desc: "(creme artesanal de kinder e nutella)",
          },
          {
            name: "BRULEE",
            price: "R$ 14 / 24",
            desc: "(creme baunilha caramelada)",
          },
          {
            name: "BRIE MORANGO",
            price: "R$ 14 / 24",
            desc: "(geleia de morango da casa ao queijo brie assado)",
          },
          {
            name: "NINHO MORANGO",
            price: "R$ 15 / 25",
            desc: "(creme artesanal de ninho e picadinhos de morango)",
          },
          {
            name: "NUTELLA MORANGO ❤️",
            price: "R$ 15 / 25",
            desc: "(nutella e picadinhos de morango)",
          },
          {
            name: "FRAISE CREMEUX",
            price: "R$ 16 / 26",
            desc: "(creme francês, geleia de morango, raspas de limão e picadinhos de morango)",
          },
          {
            name: "BAKLAVA (20mins)",
            price: "R$ 17 / 27",
            desc: "(cobertura crocante de amêdoas e chocolate branco com toque de pistâche)",
          },
          {
            name: "BANOFFEE",
            price: "R$ 17 / 27",
            desc: "(doce de leite, chantilly e banana caramelizada)",
          },
          {
            name: "NUTERANGO",
            price: "R$ 18 / 28",
            desc: "(nutella, chantilly e picadinhos de morango)",
          },
          {
            name: "SORVETE",
            price: "R$ 19 / 29",
            desc: "(sorvete, nutella e picadinhos de morango)\n SABOR DO SORVETE: ninho, chocolate, morango",
          },
          {
            name: "MINI TRIO",
            price: "R$ -- / 27",
            desc: "(3 und mini croissant sem recheio e 3 tigela doce de nutella, geleia de morango da casa e doce leite)",
          },
        ],
        imageUrl: "./product/croissant/croissant-sorvete.jpeg",
        backgroundColor: "brick_dark.5",
        textColor: "white",
        price: " OBS: os itens desta categoria serve-se temperatura natural.",
        preparationTime: "10-20 mins",
      },
      {
        title: "Kroffle 18cm \n( croissant & waffle )",
        description: [
          {
            name: "BANOFFEE (20mins) ❤️",
            price: "R$ 28",
            desc: "(doce de leite, chantilly e banana caramelizada)",
          },
          {
            name: "NUTERANGO (20mins)",
            price: "R$ 29",
            desc: "(nutella, chantilly e picadinhos de morango)",
          },
          {
            name: "SORVETE (20mins)",
            price: "R$ 30",
            desc: "(sorvete, nutella e picadinhos de morango)\n SABOR DO SORVETE: ninho, chocolate, morango",
          },
        ],
        imageUrl: "./product/croffle/nuterango-banoffee.jpeg",
        backgroundColor: "brick_dark.5",
        textColor: "white",
        price: " OBS: os itens desta categoria serve-se temperatura natural.",
        preparationTime: "20 mins",
      },
    ],
  },
  {
    cat_name: "Boulangerie",
    cat_text: "(Consulte a disponibilidade com os atendentes)",
    cat_img: "./product/1CATEGORY/boulangerie.jpg",
    items: [
      {
        title: "Pão de Mirtilo",
        description: "pão de fermentação natural ao mirtilo e cream cheese",
        imageUrl: "./product/boulangerie/pao-de-mirtilo.jpeg",
        backgroundColor: "brick_dark.5",
        textColor: "white",
        price: " R$ 10 ",
        preparationTime: "5 mins",
      },
      {
        title: "Pain Aux Raisins",
        description: "massa folhada espiral com uva de passas caramelizadas",
        imageUrl: "./product/boulangerie/pain-aux-raisins.jpeg",
        backgroundColor: "brick_dark.5",
        textColor: "white",
        price: " R$ 12 ",
        preparationTime: "5 mins",
      },
      {
        title: "Pain Au Chocolat",
        description:
          "massa folhada viennoiserie que consiste em um pedaço de massa laminada levedada em forma de cuboide, com um ou dois pedaços de chocolate ao leite no centro",
        imageUrl: "./product/boulangerie/pain-au-chocolat.jpeg",
        backgroundColor: "brick_dark.5",
        textColor: "white",
        price: " R$ 12 ",
        preparationTime: "5 mins",
      },
      {
        title: "Cinnamon Roll",
        description:
          "bolo de canela, embora exista um bolo equivalente conhecido como caracol, já que tem uma forma em espiral.\nsabor: doce de leite ou creme de oreo",
        imageUrl: "./product/boulangerie/cinnamon-roll.jpeg",
        backgroundColor: "brick_dark.5",
        textColor: "white",
        price: " R$ 12 ",
        preparationTime: "5 mins",
      },
      {
        title: "Lua Francesa",
        description:
          "folhada aberta e recheada por geleia de morango artesanal da casa e cream cheese",
        imageUrl: "./product/boulangerie/lua-francesa.jpeg",
        backgroundColor: "brick_dark.5",
        textColor: "white",
        price: " R$ 15 ",
        preparationTime: "5 mins",
      },
      {
        title: "Cruffin Argentino",
        description:
          "muffin folhada recheada por doce de leite e creme condensado de chantilly",
        imageUrl: "./product/boulangerie/cruffin-argentino.jpeg",
        backgroundColor: "brick_dark.5",
        textColor: "white",
        price: " R$ 15 ",
        preparationTime: "5 mins",
      },
      {
        title: "Europa",
        description:
          "massa folhada aberta com creme francês artesanal e frutas vermelhas",
        imageUrl: "./product/boulangerie/europa.jpeg",
        backgroundColor: "brick_dark.5",
        textColor: "white",
        price: " R$ 15 ",
        preparationTime: "5 mins",
      },
      {
        title: "Pão Russo",
        description:
          "pão artesanal amanteigado, com um gosto maravilhoso de manteiga natural que aumenta sua fome em dobro",
        imageUrl: "./product/boulangerie/pao-russo.jpeg",
        backgroundColor: "brick_dark.5",
        textColor: "white",
        price: " R$ 15 ",
        preparationTime: "5 mins",
      },
      {
        title: "Bela Russa",
        description:
          "sanduíche folhada recheada por chantilly açucarado e crocante, geleia de morango artesanal, e frutas de morango",
        imageUrl: "./product/boulangerie/bela-russa.jpeg",
        backgroundColor: "brick_dark.5",
        textColor: "white",
        price: " R$ 15 ",
        preparationTime: "5 mins",
      },
      {
        title: "Quarteto",
        description:
          "croassã grande recheada por 4 tipo de chocolates (Nutella, amargo, branco, ao leite)",
        imageUrl: "./product/boulangerie/quarteto.jpeg",
        backgroundColor: "brick_dark.5",
        textColor: "white",
        price: " R$ 15 ",
        preparationTime: "5 mins",
      },
      {
        title: "Brownie Francês",
        description:
          "brownie de Nutella ao café espresso enrolado por massa folhada",
        imageUrl: "./product/boulangerie/brownie-frances.jpeg",
        backgroundColor: "brick_dark.5",
        textColor: "white",
        price: " R$ 16 ",
        preparationTime: "5 mins",
      },
      {
        title: "Kroissant Cookie",
        description:
          "croassã coberto por cookie americano e recheado de chocolate ao nozes",
        imageUrl: "./product/boulangerie/kroissant-cookie.jpeg",
        backgroundColor: "brick_dark.5",
        textColor: "white",
        price: " R$ 16 ",
        preparationTime: "5 mins",
      },
      {
        title: "Kabibi ❤️",
        description:
          "5und esfiha folhada de carne ao tempero especial da culinária da origem árabe",
        imageUrl: "./product/boulangerie/kabibi.jpeg",
        backgroundColor: "brick_dark.5",
        textColor: "white",
        price: " R$ 27 ",
        preparationTime: "5 mins",
      },
    ],
  },
  {
    cat_name: "Confeitaria",
    cat_text: "(Consulte a disponibilidade com os atendentes)",
    cat_img: "./product/1CATEGORY/confeitaria.jpg",
    items: [
      {
        title: "Bolo de Libra",
        description:
          "bolo inglês clássico, oriundo do Norte da Europa e remonte ao início dos anos 1700, recheado por chocolate meio amargo meio doce",
        imageUrl: "./product/confeitaria/bolo-de-libra.jpeg",
        backgroundColor: "brick_dark.5",
        textColor: "white",
        price: " R$ 10 ",
        preparationTime: "5 mins",
      },
      {
        title: "Brownie S/ Glúten",
        description: "brownie sem glúten na base de duplo espresso e Nutella",
        imageUrl: "./product/confeitaria/brownie-s-gluten.jpeg",
        backgroundColor: "brick_dark.5",
        textColor: "white",
        price: " R$ 12 ",
        preparationTime: "5 mins", 
      },
      {
        title: "Danish Cookie",
        description:
          "pacote c/ 5und de biscoito amanteigada dinamarquês.\nsabor: tradicional ou chocolate",
        imageUrl: "./product/confeitaria/danish-cookie.jpeg",
        backgroundColor: "brick_dark.5",
        textColor: "white",
        price: " R$ 12/14 ",
        preparationTime: "5 mins", 
      },
      {
        title: "Tarte Doce",
        description:
          "empada doce estilo taiwanês.\nsabor: chocolate ou nutella",
        imageUrl: "./product/no_photo/em-breve.png",
        backgroundColor: "brick_dark.5",
        textColor: "white",
        price: " R$ 14 ",
        preparationTime: "5 mins",
      },
      {
        title: "American Cookie ❤️",
        description:
          "biscoito americano caprichado de chocolate ao leite e nozes.",
        imageUrl: "./product/confeitaria/american-cookie.jpeg",
        backgroundColor: "brick_dark.5",
        textColor: "white",
        price: " R$ 15 ",
        preparationTime: "5 mins",
      },
      {
        title: "Chiffoncake",
        description:
          "bolo muito fofinho e suave, criado há mais de 80 anos nos Estados Unidos.\nsabor: morango, mumu, snickers ou belgano",
        imageUrl: "./product/confeitaria/morango-chiffon.jpeg",
        backgroundColor: "brick_dark.5",
        textColor: "white",
        price: " R$ 18 ",
        preparationTime: "5 mins",
      },
    ],
  },
  {
    cat_name: "Café Quente",
    cat_text: "eitaa aquela torrada refrescante do grão de café!",
    cat_img: "./product/1CATEGORY/cafe-quente.jpg",
    items: [
      {
        title: "ATENÇÃO!",
        description: "Altere seu café para...",
        imageUrl: "",
        backgroundColor: "brick_dark.5",
        textColor: "white",
        price: "“descafeinado”, extra 1 real",
        preparationTime: "",
      },
      {
        title: "Espresso P / G",
        description: "café forte \n( 60ml / 120ml )",
        imageUrl: "./product/cafe_quente/espresso-g.jpeg",
        backgroundColor: "brick_dark.5",
        textColor: "white",
        price: " R$ 6 / 8 ",
        preparationTime: "5 mins",
      },
      {
        title: "Carioca",
        description: "café fraco\n( 200ml )\n\nOPCIONAL - Raspas de Limão",
        imageUrl: "./product/cafe_quente/carioca.jpg",
        backgroundColor: "brick_dark.5",
        textColor: "white",
        price: " R$ 10 ",
        preparationTime: "5 mins",
      },
      {
        title: "Macchiatto",
        description: "mais café do que leite cremoso \n( 120ml )",
        imageUrl: "./product/cafe_quente/macchiatto.jpeg",
        backgroundColor: "brick_dark.5",
        textColor: "white",
        price: " R$ 10 ",
        preparationTime: "7 mins",
      },
      {
        title: "Latte P / M / G",
        description:
          "mais leite cremoso do que café \n( 60ml / 120ml / 200ml )",
        imageUrl: "./product/cafe_quente/latte.jpeg",
        backgroundColor: "brick_dark.5",
        textColor: "white",
        price: " R$ 7 / 10 / 12 ",
        preparationTime: "7 mins",
      },
      {
        title: "Kappuccino P / M / G / GG",
        description:
          "mais leite espumoso do que café \n( 60ml / 120ml / 200ml / 400ml )",
        imageUrl: "./product/cafe_quente/kappuccino.jpeg",
        backgroundColor: "brick_dark.5",
        textColor: "white",
        price: " R$ 7 / 10 / 12 / 14 ",
        preparationTime: "10 mins",
      },
      {
        title: "Mocaccino",
        description: "café e leite cremoso ao chocolate suíço \n( 350ml )",
        imageUrl: "./product/cafe_quente/moccaccino.jpeg",
        backgroundColor: "brick_dark.5",
        textColor: "white",
        price: " R$ 17 ",
        preparationTime: "10 mins",
      },
      {
        title: "Délicat",
        description:
          "café e leite cremoso à base deliciosa e chantilly \n( 250ml ) \n\nBASE: chocolate ou leite condensado",
        imageUrl: "./product/cafe_quente/delicat.jpeg",
        backgroundColor: "brick_dark.5",
        textColor: "white",
        price: " R$ 18 ",
        preparationTime: "10 mins",
      },
      {
        title: "Supreme ❤️",
        description:
          "café e leite cremoso à borda deliciosa e chantilly \n( 250ml ) \n\nBORDA: nutella ou doce de leite\nOPCIONAL - Canela",
        imageUrl: "./product/cafe_quente/supreme.jpeg",
        backgroundColor: "brick_dark.5",
        textColor: "white",
        price: " R$ 20 ",
        preparationTime: "10 mins",
      },
    ],
  },
  {
    cat_name: "Chocolate Quente",
    cat_text: "cacau latino na receita suíça",
    cat_img: "./product/1CATEGORY/chocolate-quente.jfif",
    items: [
      {
        title: "Choco P / M / G",
        description:
          "chocolate suiço quente e cremoso\n( 100ml / 200ml ❤️ / 400ml )\n\nEXTRA - Marshmellow R$2, Chantilly R$2",
        imageUrl: "./product/chocolate_quente/choco.jpeg",
        backgroundColor: "brick_dark.5",
        textColor: "white",
        price: "R$ 12 / 15 / 18",
        preparationTime: "5 mins",
      },
    ],
  },
  {
    cat_name: "Chá Especial",
    cat_text: "especiaria do antigo continente",
    cat_img: "./product/1CATEGORY/cha.jfif",
    items: [
      {
        title: "Chá Quente",
        description:
          "chá quente infusionado na hora\n( 250ml )\n\nSABOR - Ilha de Java",
        imageUrl: "./product/cha/cha-quente.jpeg",
        backgroundColor: "brick_dark.5",
        textColor: "white",
        price: "R$ 15",
        preparationTime: "10 mins",
      },
      {
        title: "Chá Gelado",
        description:
          "chá gelado feito na hora\n( 400ml )\n\nSABOR - Ilha de Java",
        imageUrl: "./product/cha/cha-gelado.jpeg",
        backgroundColor: "brick_dark.5",
        textColor: "white",
        price: "R$ 18",
        preparationTime: "10 mins",
      },
    ],
  },
  {
    cat_name: "Café Gelado",
    cat_text: "método de resfriamento",
    cat_img: "./product/1CATEGORY/cafe-gelado.jfif",
    items: [
      {
        title: "Ice Latte",
        description:
          "café ao leite e cubos de gelo\n( 330ml )\n\nOPCIONAL - Leite Condensado",
        imageUrl: "./product/cafe_gelado/ice-latte.jpeg",
        backgroundColor: "brick_dark.5",
        textColor: "white",
        price: "R$ 12",
        preparationTime: "6 mins",
      },
      {
        title: "Moça Gelada ❤️",
        description:
          "leite ao cubos de gelo de café espresso, finalizado com leite condensado\n( 380ml )",
        imageUrl: "./product/cafe_gelado/moca-gelada.jpg",
        backgroundColor: "brick_dark.5",
        textColor: "white",
        price: "R$ 14",
        preparationTime: "5 mins",
      },
      {
        title: "La Suisse",
        description: "café gelado ao chocolate cremoso\n( 330ml )",
        imageUrl: "./product/cafe_gelado/la-suisse.jpeg",
        backgroundColor: "brick_dark.5",
        textColor: "white",
        price: "R$ 16",
        preparationTime: "8 mins",
      },
      {
        title: "Affogato",
        description:
          "creme de sorvete ao bisocoito dinamarquês e finalizado com chantilly, com um cafezinho\n( 280ml )",
        imageUrl: "./product/cafe_gelado/affogato.jpeg",
        backgroundColor: "brick_dark.5",
        textColor: "white",
        price: "R$ 20",
        preparationTime: "10 mins",
      },
      {
        title: "Frappuccino",
        description:
          "milkshake cremoso de café finalizado c/ chantilly\n( 400ml )",
        imageUrl: "./product/cafe_gelado/frappuccino.jpg",
        backgroundColor: "brick_dark.5",
        textColor: "white",
        price: "R$ 23",
        preparationTime: "10 mins",
      },
      {
        title: "Chococcino",
        description:
          "milkshake cremoso de café ao de chocolate finalizado c/ chantilly\n( 400ml )",
        imageUrl: "./product/cafe_gelado/chococcino.jpeg",
        backgroundColor: "brick_dark.5",
        textColor: "white",
        price: "R$ 25",
        preparationTime: "10 mins",
      },
    ],
  },
  {
    cat_name: "Milkshake",
    cat_text: "shake caprichado até derramar",
    cat_img: "./product/1CATEGORY/milkshake.jpg",
    items: [
      {
        title: "Tradicional",
        description:
          "milkshake simples com chantilly\n( 200ml / 350ml / 500ml ❤️ )\n\nSABOR: ninho, morango ou chocolate",
        imageUrl: "./product/milkshake/milkshake-tradicional.jpeg",
        backgroundColor: "brick_dark.5",
        textColor: "white",
        price: "R$ 13 / 18 / 25",
        preparationTime: "10 mins",
      },
      {
        title: "Premium",
        description:
          "milkshake elaborado com chantilly\n( 200ml / 350ml / 500ml ❤️ )\n\nSABOR: nutella, ovomaltine ou ninho morango",
        imageUrl: "./product/milkshake/milkshake-premium.jpeg",
        backgroundColor: "brick_dark.5",
        textColor: "white",
        price: "R$ 15 / 20 / 28",
        preparationTime: "10 mins",
      },
    ],
  },
  {
    cat_name: "Suco e Frutas",
    cat_text: "todos os sucos feito na base de água mineral",
    cat_img: "./product/1CATEGORY/suco-e-frutas.jpg",
    items: [
      {
        title: "ATENÇÃO!",
        description: "Adicione ao seu suco fica mais gostoso!",
        imageUrl: "",
        backgroundColor: "brick_dark.5",
        textColor: "white",
        price: "“leite”, extra 2 reais",
        preparationTime: "",
      },
      {
        title: "Suco da Polpa (330ml)",
        description: [
          { name: "manga", price: "R$ 10" },
          { name: "goiaba", price: "R$ 10" },
          { name: "goiaba", price: "R$ 10" },
          { name: "graviola", price: "R$ 10" },
          { name: "uva", price: "R$ 12" },
          { name: "abacaxi (hortelã)", price: "R$ 12" },
          { name: "acerola (hortelã)", price: "R$ 12" },
          { name: "caju (hortelã)", price: "R$ 12" },
          { name: "cajá (hortelã)", price: "R$ 12" },
          { name: "maracujá (hortelã)", price: "R$ 12" },
          { name: "maracujá suíça ❤️", price: "R$ 15" },
        ],
        imageUrl: "./product/suco/suco-abacaxi-hortela.jpeg",
        backgroundColor: "brick_dark.5",
        textColor: "white",
        price: "",
        preparationTime: "10 mins",
      },
      {
        title: "Suco da Fruta\n(330ml)",
        description: [
          { name: "laranja (puro laranja)", price: "R$ 12" },
          { name: "limonada (hortelã)", price: "R$ 12" },
          { name: "limonada suíça", price: "R$ 15" },
          { name: "melão (hortelã)", price: "R$ 13" },
          { name: "morango", price: "R$ 13" },
          { name: "morango laranja ❤️", price: "R$ 15" },
          { name: "morango uva", price: "R$ 15" },
          { name: "morango limão", price: "R$ 15" },
        ],
        imageUrl: "./product/suco/suco-morango.jpeg",
        backgroundColor: "brick_dark.5",
        textColor: "white",
        price: "",
        preparationTime: "10 mins",
      },
      {
        title: "Frozen",
        description: "( 380ml )\n\nSABOR - Frutas Vermelhas",
        imageUrl: "./product/suco/frozen.jpeg",
        backgroundColor: "brick_dark.5",
        textColor: "white",
        price: "R$ 20",
        preparationTime: "10 mins",
      },
      {
        title: "Frutas Bowl",
        description:
          "( 200g )\n\nOPCIONAL - Leite Condensado, Mel, Canela\nADICIONAL - Granola R$ 3, Iogurte R$ 5",
        imageUrl: "./product/suco/salada-frutas.jpeg",
        backgroundColor: "brick_dark.5",
        textColor: "white",
        price: "R$ 15",
        preparationTime: "10 mins",
      },
    ],
  },
  {
    cat_name: "Drinks e Coquetel",
    cat_text: "bebidas em prol da mixologia",
    cat_img: "./product/1CATEGORY/drink-e-coquetel.jfif",
    items: [
      {
        title: "Soda Italiana",
        description:
          "drink refrescante sem álcool\n( 300ml )\n\nSABOR - Morango, Maçã Verde, Frutas Vermelhas ou Tangerina",
        imageUrl: "./product/drinks/soda-italiana.jpeg",
        backgroundColor: "brick_dark.5",
        textColor: "white",
        price: "R$ 15",
        preparationTime: "10 mins",
      },
      {
        title: "Caipirinha Limão",
        description:
          "coquetel de cachaça/vodka favoritado\n( 300ml )\n\nSABOR - Limão",
        imageUrl: "./product/drinks/caipirinha.jpeg",
        backgroundColor: "brick_dark.5",
        textColor: "white",
        price: "R$ 16",
        preparationTime: "10 mins",
      },
      {
        title: "Caipiroska",
        description:
          "apadatação de caipiroska feito deliciosamente com frutas e limão\n( 300ml )\n\nSABOR - Limão, Morango, Frutas Vermelhas ou Tangerina",
        imageUrl: "./product/drinks/berry-caipiroska.jpeg",
        backgroundColor: "brick_dark.5",
        textColor: "white",
        price: "R$ 18",
        preparationTime: "10 mins",
      },
      {
        title: "Mojito",
        description:
          "drink que floresceu na noite de havana usando ingredientes nativos do caribe\n( 330ml )",
        imageUrl: "./product/drinks/mojito.jpeg",
        backgroundColor: "brick_dark.5",
        textColor: "white",
        price: "R$ 25",
        preparationTime: "10 mins",
      },
      {
        title: "Nami Margarita",
        description:
          "tequila que remete ao romance do rei de piratas no pôr do sol do oceano pacífico\n( 230ml )",
        imageUrl: "./product/no_photo/em-breve.png",
        backgroundColor: "brick_dark.5",
        textColor: "white",
        price: "R$ 25",
        preparationTime: "10 mins",
      },
      {
        title: "Cerveja de Uva",
        description: "cerveja de uva artesanal\n( 600ml )",
        imageUrl: "./product/no_photo/em-breve.png",
        backgroundColor: "brick_dark.5",
        textColor: "white",
        price: "R$ 25",
        preparationTime: "10 mins",
      },
      {
        title: "Mary Gin ❤️",
        description:
          "o favoritado morango mergulhado no banho de misturas congelados e um toque de gin\n( 480ml )",
        imageUrl: "./product/drinks/mary-gin.jpeg",
        backgroundColor: "brick_dark.5",
        textColor: "white",
        price: "R$ 25",
        preparationTime: "10 mins",
      },
      {
        title: "Apple",
        description:
          "drink com inspiração bíblica que você toma e fica apaixonadinho por conta da famosa maçã\n( 480ml )",
        imageUrl: "./product/drinks/apple.jpeg",
        backgroundColor: "brick_dark.5",
        textColor: "white",
        price: "R$ 25",
        preparationTime: "10 mins",
      },
      {
        title: "Icemarula",
        description:
          "coquetel cremoso africano com fruto da amarula extraído por elefantes\n( 380ml )",
        imageUrl: "./product/drinks/icemarula.jpeg",
        backgroundColor: "brick_dark.5",
        textColor: "white",
        price: "R$ 27",
        preparationTime: "10 mins",
      },
      {
        title: "Madame Yang",
        description:
          "drink asiática com um fruto milenar, lichia, cultivada pela famosa imperatriz chinesa\n( 300ml )",
        imageUrl: "./product/no_photo/em-breve.png",
        backgroundColor: "brick_dark.5",
        textColor: "white",
        price: "R$ 30",
        preparationTime: "10 mins",
      },
    ],
  },
  {
    cat_name: "Vinho",
    cat_text: "sob avaliação vivino 2023",
    cat_img: "./product/1CATEGORY/vinho.jpg",
    items: [
      {
        title: "Pergola \n(Brasileiro Suave)",
        description: [{ name: "Seleção-Suave Tinto (★ 3.5)", price: "R$ 35" }],
        imageUrl: "./product/no_photo/em-breve.png",
        backgroundColor: "brick_dark.5",
        textColor: "white",
        price: "Vinícola com grande impulso para população Campestre.",
        preparationTime: "",
      },
      {
        title: "Casa Valduga \n(Brasileiro Suave)",
        description: [
          {
            name: "Naturelle C.S., Merlot, Marselan (★ 3.9)",
            price: "R$ 100",
          },
        ],
        imageUrl: "./product/no_photo/em-breve.png",
        backgroundColor: "brick_dark.5",
        textColor: "white",
        price: "Vinícola fundado pela Família Valduga e imigrante italianos.",
        preparationTime: "",
      },
      {
        title: "Andean Vineyards \n(Argentino Seco)",
        description: [
          {
            name: "The Grill Master Fan Club C.S. - Malbec (★ 3.5)",
            price: "R$ 65",
          },
        ],
        imageUrl: "./product/no_photo/em-breve.png",
        backgroundColor: "brick_dark.5",
        textColor: "white",
        price: "Vinícola que visa as necessidades do mercado.",
        preparationTime: "",
      },
      {
        title: "Trapiche \n(Argentino Seco)",
        description: [
          { name: "Vineyards Malbec 2021 (★ 3.7)", price: "R$ 70" },
        ],
        imageUrl: "./product/no_photo/em-breve.png",
        backgroundColor: "brick_dark.5",
        textColor: "white",
        price: "Vinícola com lema de onde há solo, o céu é o limite.",
        preparationTime: "",
      },
      {
        title: "D.V. Catena \n(Argentino Seco)",
        description: [
          { name: "Cabernet - Malbec 2021 (★ 4.3)", price: "R$ 180" },
        ],
        imageUrl: "./product/no_photo/em-breve.png",
        backgroundColor: "brick_dark.5",
        textColor: "white",
        price: "Vinho conhecido pelas notas amadeirado e chocolate.",
        preparationTime: "",
      },
      {
        title: "Montes Toscanini \n(Uruguaio Seco)",
        description: [
          { name: "Familiar Tannat Reserva 2021 (★ 4.0)", price: "R$ 140" },
        ],
        imageUrl: "./product/no_photo/em-breve.png",
        backgroundColor: "brick_dark.5",
        textColor: "white",
        price: "Quatro gerações familiar, com a mesma paixão, a excelência.",
        preparationTime: "",
      },
      {
        title: "The 7th Generation - G7 \n(Chileno Seco)",
        description: [{ name: "C.S. Reserva (★ 3.3)", price: "R$ 45" }],
        imageUrl: "./product/no_photo/em-breve.png",
        backgroundColor: "brick_dark.5",
        textColor: "white",
        price: "Produção milhões no centro de Vale de Loncomilla, Chile.",
        preparationTime: "",
      },
      {
        title: "Santa Carolina \n(Chileno Seco)",
        description: [
          {
            name: "C.S. Merlot Reservado (★ 3.6)",
            price: "R$ 50",
          },
          { name: "Carménère Reservado (★ 3.5)", price: "R$ 50" },
        ],
        imageUrl: "./product/no_photo/em-breve.png",
        backgroundColor: "brick_dark.5",
        textColor: "white",
        price: "Vinho chileno reconhecido internacionalmente.",
        preparationTime: "",
      },
      {
        title: "Concha y Toro \n(Chileno Seco)",
        description: [
          { name: "C.S. Reservado (★ 3.7)", price: "R$ 55" },
          { name: "Carménère Reservado (★ 3.7)", price: "R$ 65" },
        ],
        imageUrl: "./product/no_photo/em-breve.png",
        backgroundColor: "brick_dark.5",
        textColor: "white",
        price: "Fundação de vinícola com uvas famosas da antiguidade.",
        preparationTime: "",
      },
      {
        title: "Santa Helena \n(Chileno Seco)",
        description: [
          { name: "Malbec Reservado (★ 3.5)", price: "R$ 55" },
          { name: "Merlot Reservado  (★ 3.5)", price: "R$ 55" },
          { name: "Carménère Reservado (★ 3.6)", price: "R$ 60" },
          { name: "C.S. Reservado (★ 3.6)", price: "R$ 60" },
        ],
        imageUrl: "./product/no_photo/em-breve.png",
        backgroundColor: "brick_dark.5",
        textColor: "white",
        price: "Vinho com toque marcante e característicos des uvas.",
        preparationTime: "",
      },
      {
        title: "Casillero Del Diablo \n(Chileno Seco)",
        description: [
          { name: "C.S.  2020/2021 (★ 3.8)", price: "R$ 90" },
          { name: "Rosé (Reserva) (★ 3.9)", price: "R$ 90" },
          { name: "Carménère 2021 (★ 3.8)", price: "R$ 95" },
          { name: "Red Blend  2021 (★ 3.8)", price: "R$ 98" },
        ],
        imageUrl: "./product/no_photo/em-breve.png",
        backgroundColor: "brick_dark.5",
        textColor: "white",
        price: "Um boato que se tornou lenda: a história de Casillero.",
        preparationTime: "",
      },
      {
        title: "San Pedro \n(Chileno Seco)",
        description: [
          {
            name: "Gato Negro 9 Lives Carménère Reserva 2022 (★ 3.8)",
            price: "R$ 95",
          },
        ],
        imageUrl: "./product/no_photo/em-breve.png",
        backgroundColor: "brick_dark.5",
        textColor: "white",
        price: "Vinícola fundada com a união dos irmãos Correa.",
        preparationTime: "",
      },
      {
        title: "Tarapacá \n(Chileno Seco)",
        description: [
          { name: "Carménère Reserva 2021 (★ 4.1)", price: "R$ 130" },
        ],
        imageUrl: "./product/no_photo/em-breve.png",
        backgroundColor: "brick_dark.5",
        textColor: "white",
        price: "Vinícola no coração de Vale do Maipo, Chile.",
        preparationTime: "",
      },
      {
        title: "Félix Solís \n(Espanhol Seco)",
        description: [
          { name: "Viña Albali Tempranillo (★ 3.7)", price: "R$ 90" },
        ],
        imageUrl: "./product/no_photo/em-breve.png",
        backgroundColor: "brick_dark.5",
        textColor: "white",
        price: "Fusão milenar resultante do casal espanhol Solís.",
        preparationTime: "",
      },
      {
        title: "Ségur Estates \n(Português Seco)",
        description: [{ name: "Alecrim Tinto (★ 3.7)", price: "R$ 65" }],
        imageUrl: "./product/no_photo/em-breve.png",
        backgroundColor: "brick_dark.5",
        textColor: "white",
        price: "Fundador conhecido 'Prince des Vignes' pelo Rei.",
        preparationTime: "",
      },
      {
        title: "Quinta de Bons-Ventos \n(Português Seco & Branco/Rosé)",
        description: [
          { name: "Verde Branco (★ 3.7)", price: "R$ 90" },
          { name: "Branco 2022 (★ 3.8)", price: "R$ 90" },
          { name: "Tinto 2021 (★ 3.9)", price: "R$ 110" },
          { name: "Rosé (Reserva) (★ 3.9)", price: "R$ 120" },
          { name: "Fresh Branco 2021 (★ 4.1)", price: "R$ 120" },
        ],
        imageUrl: "./product/no_photo/em-breve.png",
        backgroundColor: "brick_dark.5",
        textColor: "white",
        price: "Vinho acessível para apreciadores de qualidade.",
        preparationTime: "",
      },
      {
        title: "Casal Garcia \n(Português Verde)",
        description: [{ name: "Vinho Verde N.V. (★ 3.9)", price: "R$ 100" }],
        imageUrl: "./product/no_photo/em-breve.png",
        backgroundColor: "brick_dark.5",
        textColor: "white",
        price: "Favoritada pela população português desde 1939.",
        preparationTime: "",
      },
      {
        title: "Castellani \n(Italiano Seco)",
        description: [
          { name: "Forte Ambrone Rosso d'Italia (★ 4.0)", price: "R$ 150" },
        ],
        imageUrl: "./product/no_photo/em-breve.png",
        backgroundColor: "brick_dark.5",
        textColor: "white",
        price: "Presença do mestre de vinho no processo de fermentação.",
        preparationTime: "",
      },
      {
        title: "Freixenet \n(Italiano Seco)",
        description: [{ name: "Chianti (★ 3.9)", price: "R$ 155" }],
        imageUrl: "./product/no_photo/em-breve.png",
        backgroundColor: "brick_dark.5",
        textColor: "white",
        price: "Fruto da dinastia Casa Sala, fundada por Francesc Sala.",
        preparationTime: "",
      },
      {
        title: "Codici \n(Italiano Seco)",
        description: [
          { name: "Masserie Primitivo 2021 (★ 4.1)", price: "R$ 165" },
        ],
        imageUrl: "./product/no_photo/em-breve.png",
        backgroundColor: "brick_dark.5",
        textColor: "white",
        price: "Ao redor da arquitetura mais famosa da Europa.",
        preparationTime: "",
      },
      {
        title: "Piscine \n(Francês Rosé)",
        description: [{ name: "Sea Sun Rosé (★ 3.9)", price: "R$ 100" }],
        imageUrl: "./product/no_photo/em-breve.png",
        backgroundColor: "brick_dark.5",
        textColor: "white",
        price: "Inspiração proveniente da refrescância mediterrâneo.",
        preparationTime: "",
      },
      {
        title: "I Union des Têtes de Mule \n(Francês Seco)",
        description: [
          { name: "Ted the Mule Cuvée Réserve (★ 4.0)", price: "R$ 150" },
        ],
        imageUrl: "./product/no_photo/em-breve.png",
        backgroundColor: "brick_dark.5",
        textColor: "white",
        price: "Burro chute na cara dos apreciadores de vinho.",
        preparationTime: "",
      },
    ],
  },
  {
    cat_name: "Espumante",
    cat_text: "sob avaliação vivino 2023",
    cat_img: "./product/1CATEGORY/espumante.jpg",
    items: [
      {
        title: "Panizzon \n(Brasileiro)",
        description: [
          { name: "Prosecco Brut (★ 3.6)", price: "R$ 80" },
          { name: "Chardonnay N.V. (★ 3.6)", price: "R$ 80" },
          { name: "Moscatel N.V. (★ 3.6)", price: "R$ 80" },
        ],
        imageUrl: "./product/no_photo/em-breve.png",
        backgroundColor: "brick_dark.5",
        textColor: "white",
        price: "Município como maior produtor de vinhos do Brasil.",
        preparationTime: "",
      },
      {
        title: "Salton \n(Brasileiro)",
        description: [
          { name: "Poética Brut Rosé (★ 3.6)", price: "R$ 70" },
          { name: "Espumante Demi-Sec (★ 3.6)", price: "R$ 70" },
        ],
        imageUrl: "./product/no_photo/em-breve.png",
        backgroundColor: "brick_dark.5",
        textColor: "white",
        price: "Vinícola que vinificava informalmente pelos italianos.",
        preparationTime: "",
      },
      {
        title: "Sogno Italiano \n(Italiano)",
        description: [
          { name: "Lambrusco Rosso Frisante (★ 3.8)", price: "R$ 70" },
        ],
        imageUrl: "./product/no_photo/em-breve.png",
        backgroundColor: "brick_dark.5",
        textColor: "white",
        price: "Vinícola perto da populaçã que toma vinho todo dia.",
        preparationTime: "",
      },
    ],
  },
];
