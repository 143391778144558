
const KroissantMap = () => {
  return (
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d989.5752490121993!2d-35.8754728513359!3d-7.20646108013625!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x7ac1ff2db9187b1%3A0x8fce92ec6b75947c!2sKroissant!5e0!3m2!1spt-BR!2sbr!4v1703603232683!5m2!1spt-BR!2sbr"
        width="100%"
        height="450"
        frameBorder={"0"}
        allowFullScreen
        loading="lazy"
        referrerPolicy="no-referrer-when-downgrade"
        title="kroissant-map"
      ></iframe>
  );
};

export default KroissantMap;

/* AIzaSyDN1P--k_w1czaZJIV_5gc2fpevpdGr0WI */
