"use client";

import {
  IconButton,
  Box,
  CloseButton,
  Flex,
  Icon,
  useColorModeValue,
  Drawer,
  DrawerContent,
  useDisclosure,
  BoxProps,
  FlexProps,
  Image,
} from "@chakra-ui/react";
import {
  FiHome,
  FiBook,
  FiShoppingCart,
  FiShoppingBag,
  FiCalendar,
  FiUser,
  FiList,
} from "react-icons/fi";
import { IconType } from "react-icons";
import { ReactText } from "react";
import { Link as RouterLink, Outlet } from "react-router-dom";

interface LinkItemProps {
  name: string;
  icon: IconType;
  fake_name: string;
  link?: string;
}
const LinkItems: Array<LinkItemProps> = [
  { name: "intro", icon: FiHome, fake_name: "Introdução" },
  { name: "cardapio", icon: FiBook, fake_name: "Cardápio (Consumo Local)" },
  {
    name: "delivery",
    icon: FiShoppingCart,
    fake_name: "Retirada/Delivery (Kroissant)",
    link: "https://tinyurl.com/kroissantcgpb",
  },
  {
    name: "delivery",
    icon: FiShoppingCart,
    fake_name: "Retirada/Delivery (Gyoza)",
    link: "https://tinyurl.com/gyozacgpb",
  },
  {
    name: "encomenda",
    icon: FiShoppingBag,
    fake_name: "Encomenda Para Eventos",
    link: "https://wa.me/5583986182324/?text=Olá! Quero saber sobre encomenda de Kroissant.",
  },
  {
    name: "reserva",
    icon: FiCalendar,
    fake_name: "Quero Reservar Mesa",
    link: "https://wa.me/5583986182324/?text=Olá! Quero saber sobre reserva de Kroissant.",
  },
  {
    name: "contato",
    icon: FiUser,
    fake_name: "Contato",
    link: "https://wa.me/5583986182324/?text=Olá! Quero reportar/sugerir problema que aconteceu comigo na loja.",
  },
];

export default function SimpleSidebar() {
  const { isOpen, onOpen, onClose } = useDisclosure();

  // Set isOpen to true by default to keep the sidebar open
  const defaultIsOpen = true;

  return (
    <Box minH="100vh" bg={useColorModeValue("white.100", "white.900")}>
      {/* Pass defaultIsOpen as the initial state */}
      <SidebarContent
        onClose={onClose}
        display={{ base: "none", md: "block" }}
        isOpen={defaultIsOpen}
      />
      <Drawer
        isOpen={isOpen}
        placement="left"
        onClose={onClose}
        returnFocusOnClose={false}
        onOverlayClick={onClose}
        size="full"
      >
        <DrawerContent>
          <SidebarContent onClose={onClose} isOpen={isOpen} />
        </DrawerContent>
      </Drawer>
      {/* mobilenav */}
      <MobileNav display={{ base: "flex", md: "none" }} onOpen={onOpen} />
      <Box>
        <Outlet />
      </Box>
    </Box>
  );
}

interface SidebarProps extends BoxProps {
  onClose: () => void;
  isOpen: boolean; // Add isOpen prop
}

const SidebarContent = ({ onClose, isOpen, ...rest }: SidebarProps) => {
  return (
    <Box
      color={"#d2ac67"}
      bg={useColorModeValue("white", "gray.900")}
      w={{ base: "full", md: 60 }}
      pos="fixed"
      height="100vh" // Set height to 100% of the viewport height
      overflowY="scroll" // Enable vertical scrolling
      display={{ base: isOpen ? "block" : "none", md: "block" }} // Set the isOpen state here
      {...rest}
    >
      <Flex
        h="20"
        alignItems="center"
        mr="8"
        my="2rem"
        justifyContent="space-between"
      >
        <Image src="./logo/gold-letter.png" boxSize={"45%"} h={""} p={"5%"}></Image>
        <CloseButton display={{ base: "flex", md: "none" }} onClick={onClose} />
      </Flex>
      {LinkItems.map((link, index) => (
        <NavItem
          key={`${link.name}-${index}`}
          icon={link.icon}
          border={"1px"}
          mt={"11%"}
          justify={"center"}
          to={`/${link.name.toLowerCase()}`}
          onClose={onClose}
          link={link.link}
        >
          {link.fake_name}
        </NavItem>
      ))}
    </Box>
  );
};

interface NavItemProps extends FlexProps {
  icon: IconType;
  children: ReactText;
  to: string;
  onClose: () => void;
  link?: string;
}
const NavItem = ({ icon, children, to, onClose, ...rest }: NavItemProps) => {
  const handleClick = () => {
    onClose();
  };

  const externalLink = rest.link ? rest.link : null;

  return (
    <RouterLink to={to} style={{ textDecoration: "none" }}>
      <Flex
        align="center"
        p="4"
        mx="4"
        borderRadius="lg"
        role="group"
        cursor="pointer"
        _hover={{
          bg: "cyan.400",
          color: "white",
        }}
        onClick={
          externalLink ? () => window.open(externalLink, "_blank") : handleClick
        }
        {...rest}
      >
        {icon && (
          <Icon
            mr="4"
            fontSize="16"
            _groupHover={{
              color: "white",
            }}
            as={icon}
          />
        )}
        {children}
      </Flex>
    </RouterLink>
  );
};

interface MobileProps extends FlexProps {
  onOpen: () => void;
}
const MobileNav = ({ onOpen, ...rest }: MobileProps) => {
  return (
    <Flex
      position="fixed" // Set position to fixed
      top={0} // Place it at the top
      left={0} // Place it on the left
      right={0} // Place it on the right
      ml={{ base: 0, md: 60 }}
      px={{ base: 4, md: 24 }}
      height="20"
      alignItems="center"
      justifyContent="space-between"
      zIndex={10}
      {...rest}
    >
      <Image src="./logo/white-logo.png" boxSize={"20%"} h={""} />
      <IconButton
        onClick={onOpen}
        aria-label="open menu"
        icon={<FiList />}
        color={"white"}
        fontWeight={"2rem"}
        backgroundColor={"#441B15"}
      />
    </Flex>
  );
};
