import { extendTheme } from "@chakra-ui/react";

const colors = {
  papaya_dark: {
    1: "#FEECD4",
    2: "#FDDCB2",
    3: "#FCCE92",
    4: "#FBC071",
    5: "#FBB151",
    6: "#FAA331",
    7: "#F99410",
    8: "#E28306",
    9: "#C27005",
    10: "#A25D04",
    11: "#814B03",
    12: "#613802",
    13: "#412502",
    14: "#201301",
    15: "#000000",
  },
  ebony_dark: {
    1: "#565A4C",
    2: "#515448",
    3: "#4B4E42",
    4: "#44473D",
    5: "#3E4137",
    6: "#383A32",
    7: "#32342C",
    8: "#2C2D27",
    9: "#252721",
    10: "#1F201C",
    11: "#191A16",
    12: "#131311",
    13: "#0C0D0B",
    14: "#060606",
    15: "#000000",
  },
  brick_dark: {
    1: "#BC4A3C",
    2: "#B04638",
    3: "#A34033",
    4: "#953B2F",
    5: "#88362B",
    6: "#7A3027",
    7: "#6D2B22",
    8: "#5F261E",
    9: "#51201A",
    10: "#441B15",
    11: "#361511",
    12: "#29100D",
    13: "#1B0B09",
    14: "#0E0504",
    15: "#000000",
  },
  green_dark: {
    1: '#194121',
    2: '#16391D',
    3: '#133119',
    4: '#102915',
    5: '#0C2010',
    6: '#09180C',
    7: '#061008',
    8: '#030804',
  },
};

const fonts = {
  body: "Oswald",
  heading: "Indie Flower",
};

const theme = extendTheme({
  colors,
  fonts,
  styles: {
    global: {
      body: {
        bg: "papaya_dark.1", // Set your default background color
        color: "brick_dark.10", // Set your default text color
      },
    },
  },
});

export default theme;
