import { Flex, Heading, Stack, Image, Text, Link } from "@chakra-ui/react";
import KroissantMap from "../../../component/KroissantMap";

export default function Footer() {
  return (
    <Stack
      h={{ base: "auto", md: "100%" }}
      backgroundColor={"papaya_dark.1"} 
      color={"papaya_dark.11"}
    >
      <Flex
        justify={"center"}
        flexDirection={"column"}
        align={"center"}
        p={"2rem"}
        gap={"1rem"}
      >
        <Heading
          textAlign={"center"}
          mt={"15%"}
          mb={"5%"}
          backgroundColor={"brick_dark.4"}
          color={"papaya_dark.1"}
          w={"100vw"}
        >
          AWARDSᴮᴿ
        </Heading>
        <Image src="./component/guru.png" />

        <Heading
          textAlign={"center"}
          mt={"15%"}
          mb={"5%"}
          backgroundColor={"brick_dark.4"}
          color={"papaya_dark.1"}
          w={"100vw"}
        >
          Como chegar na loja?
        </Heading>
        <KroissantMap />

        <Text
          textAlign={"center"}
          mt={"3rem"}
          backgroundColor={"brick_dark.4"}
          color={"papaya_dark.1"}
          w={"100vw"}
          mb={"0"}
        >
          <Link textDecor={"underline"}>GRUPOHSU</Link>
          ® 2023. All rights reserved. <br />
          PS: Site desenvolvido por{" "}
          <Link
            href="https://instagram.com/juliohsuchu"
            color={"papaya_dark.5"}
          >
            JHC™
          </Link>
          , TI responsável.
        </Text>
      </Flex>
    </Stack>
  );
}
