'use client'

import { Flex } from '@chakra-ui/react'

export default function WithBackgroundImage() {
  return (
    <Flex
      w={'full'}
      h={'120vh'}
      backgroundImage={
        './bg/header-bg.png'
      }
      backgroundSize={'cover'}
      backgroundPosition={'center center'}>
    </Flex>
  )
}