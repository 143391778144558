import {
  Card,
  Stack,
  CardBody,
  Heading,
  Text,
  SimpleGrid,
  CardHeader,
  Flex,
  Box,
} from "@chakra-ui/react";
import { menuData } from "../../data/menu";
import NavbarMenu from "../../component/NavbarMenu";
import ItemCardBody from "../../component/ItemCardBody";
import SimpleImageSlider from "react-simple-image-slider";

const images = [
  { url: "./product/1NOVIDADE/sopa-bg.png" },
  { url: "./product/1NOVIDADE/parma-bg.jpg" },
  { url: "./product/1NOVIDADE/sorvete-bg.jpg" },
  { url: "./product/1NOVIDADE/mascavo-bg.jpg" },
  { url: "./product/1NOVIDADE/chiffon-bg.jpg" },
  { url: "./product/1NOVIDADE/banoffee-bg.jpg" },
  { url: "./product/1NOVIDADE/kinder-bg.png" },
  { url: "./product/1NOVIDADE/jianjiao-bg.png" },
  { url: "./product/1NOVIDADE/salada-bg.png" },
  { url: "./product/1NOVIDADE/drinks-bg.png" },
];

export default function CardapioPage() {
  return (
    <Box backgroundColor={"papaya_dark.1"}>
      <Box id="novidades">
        <SimpleImageSlider
          width={"100vw"}
          height={"95vh"}
          images={images}
          showBullets={true}
          showNavs={true}
          autoPlay
        />
      </Box>
      <Stack spacing={"20"}>
        {menuData.map((category, categoryIndex) => (
          <Stack key={categoryIndex} spacing={"0"}>
            <Flex
              maxW={{ base: "100%", sm: "100%" }}
              h={"45vh"}
              backgroundImage={category.cat_img}
              backgroundSize={"cover"}
              backgroundPosition={"center center"}
              id={category.cat_name.toLowerCase()}
            />
            <Card
              direction={{ base: "column", sm: "row" }}
              overflow="hidden"
              variant="outline"
              backgroundColor={"papaya_dark.1"}
            >
              <Stack>
                <CardBody>
                  <SimpleGrid
                    spacing={4}
                    templateColumns="repeat(auto-fill, minmax(300px, 1fr))"
                  >
                    <Stack textAlign={"center"}>
                      <CardHeader w={"100%"} color={"brick_dark.8"}>
                        <Heading>{category.cat_name}</Heading>
                        <Text>{category.cat_text}</Text>
                      </CardHeader>
                      <CardBody>
                        {category.items.map((item1, itemIndex) => (
                          <Card
                            key={itemIndex}
                            maxW="sm"
                            mb={"1rem"}
                            backgroundColor={item1.backgroundColor}
                            color={item1.textColor}
                          >
                            <ItemCardBody item={item1} category={category} />
                          </Card>
                        ))}
                      </CardBody>
                    </Stack>
                  </SimpleGrid>
                </CardBody>
              </Stack>
            </Card>
          </Stack>
        ))}
      </Stack>
      <Box position="fixed" bottom="0" left="0" right="0" h={"5vh"}>
        <NavbarMenu />
      </Box>
    </Box>
  );
}
