import { Box, Flex, Heading, Image, Stack, Text } from "@chakra-ui/react";
import Spoiler from "../../../component/Spoiler";

interface SectionHeadingProps {
  text: string;
}

const SectionHeading: React.FC<SectionHeadingProps> = ({ text }) => (
  <Heading size="lg" textTransform="uppercase">
    {text}
  </Heading>
);

interface SectionTextProps {
  children: React.ReactNode;
}

const SectionText: React.FC<SectionTextProps> = ({ children }) => (
  <Text pt="2" fontSize="sm">
    {children}
  </Text>
);

interface HistorySectionProps {
  heading: string;
  children: React.ReactNode;
  textAlign?: "left" | "right";
}

const HistorySection: React.FC<HistorySectionProps> = ({
  heading,
  children,
  textAlign = "left",
}) => (
  <Box textAlign={textAlign}>
    <SectionHeading text={heading} />
    {children}
  </Box>
);

export default function History() {
  const stackSpacing = "10";

  return (
    <Stack>
      <Heading as="h1" textAlign="center" mt={"5rem"}>
        nunca comeu um croassã de verdade?
      </Heading>
      <Image src="./component/croissant-tabua-2.png" mb="0" />
      <Spoiler />
      <Flex
        backgroundColor={"brick_dark.11"}
        color="papaya_dark.1"
        h="150%"
        flexDirection={"column"}
        mt={"8%"}
        mb={"-20%"}
      >
        <Image src="./bg/history-bg-2.png" h={"10%"} mt={"-1%"} />
        <Stack
          spacing={stackSpacing}
          textAlign="left"
          p="2rem"
          direction="column"
          justify="space-evenly"
          align="center"
          h="75%"
        >
          <HistorySection heading="O que é croissant?" textAlign="left">
            <SectionText>
              Aportuguesado como croassã (do francês croissant), massa folhada
              em formato de meia-lua.
              <br />
              <br />A origem atribuída aos padeiros austríacos, na Batalha de
              Viena, enquanto trabalhavam à noite, esses padeiros ouviram o
              barulho que o inimigo otomano fazia ao cavar um túnel e, ao dar o
              alarme sobre o que estava acontecendo, conseguiram impedir o êxito
              do ataque. Depois com a comemoração da vitória, os padeiros
              ofereciam essas divinas inspiradas com a bandeira do inimigo!
            </SectionText>
          </HistorySection>
          <HistorySection heading="E Kroissant...?" textAlign="right">
            <SectionText>
              O Kroissant (A Famosa Croassaria), foi fundado no dia 10 de agosto
              2021 em Campina Grande - PB, carregando a missão de espalhar o
              verdadeiro croissant feito 100% na base de manteiga natural e sua
              crocância peculiar para mundo gastronômico. Sem contar os
              pães/doces internacionais e qualificadas existenten para matar sua
              fome!
            </SectionText>
          </HistorySection>
          <Box>
            <HistorySection heading="Como surgiu a ideia?" textAlign="left">
              <SectionText>
                A inspiração segue da família Hsu e o chef Samuel Hsu Chu,
                mestre profissional direcionado em panificação, por onde seguiu
                sua formação acadêmica gastronômica na Ásia.
                <br /> <br /> Logo depois contando com mais uma experiência
                extraordinária de especialização profunda rigorosa, nos
                boulangeries típicas da Europa, por onde concentra-se tons de
                padeiros com personalidades peculiares de uso e entendimento
                sobre mixologia 'levain', em outras palavras, fermentação
                natural. 
                <br /> <br /> Além disso, o nosso chef ainda participou em várias
                competições internacionais no mundo inteiro, principalmente na
                Singapura, aonde usou-se sua criatividade na recriação de
                personagens e paisagens naturais que extraiu o antigo histórico
                da antiga Euro-Asia, isso tudo usando ingredientes de
                consumíveis, tal como mostrado nas fotos abaixo...
                <br /> <br /> 
              </SectionText>
            </HistorySection>
            <Stack>
              <Image src="./component/sam-samurai.jpeg" mt="1rem" />
              <Image src="./component/sam-castle.jpeg" mt="1rem" />
            </Stack>
          </Box>
        </Stack>
        <Image src="./bg/history-bg-1.png" h={"10%"} />
      </Flex>
    </Stack>
  );
}
